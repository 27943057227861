<template>
  <div class="index-page">
    <Header></Header>
    <div class="banner">
      <div class="swiper-container" id="banner">
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="(v, k) in bannerData" :key="k">
            <div class="item" @click="toBannerInfo(v)">
              <img :src="v.coverImage" class="bg" />

              <img :src="v.titleImage" class="text" />
            </div>
          </div>
        </div>
        <!-- Add Pagination -->
        <div class="swiper-pagination"></div>
      </div>
    </div>

    <div class="desc">
      <div class="video-box">
        <video
          src="https://wsrsh.oss-cn-beijing.aliyuncs.com/uploadfile/video/%E7%8E%8B%E8%88%8D%E4%BA%BA%E5%95%86%E4%BC%9A%E5%AE%A3%E4%BC%A0%E7%89%87.mp4"
          controls id="descVideo"></video>
        <img src="@/static/images/demo.png" alt="" class="poster" @click="videoPlay()" />
        <img src="@/static/images/icon-play.png" alt="" class="play" @click="videoPlay()" />
      </div>
      <div class="desc-box">
        <img src="@/static/images/index-desc-title.png" alt="" />
        <div>
          济南市历城区王舍人街道商会是具有独立法人地位的王舍人街道民营企业依法自愿结成的地方性、联合性、非盈利性的社会组织。会员单位分布在智能制造、高端设备、生科康养、医疗器械、精密仪器、汽车营销、餐饮住宿、商贸物流、创业孵化、建筑业及房地产等多个领域。
        </div>
      </div>
    </div>

    <div class="news">
      <div class="public-title">商会要闻</div>

      <div class="list">
        <div class="item" v-for="(v, k) in news" :key="k" @click="$router.push('news-info?id=' + v.id)">
          <img :src="v.coverImage" alt="" />
          <div class="day">{{ v.createTime.substr(8, 2) }}</div>
          <div class="year">{{ v.createTime.substr(0, 7) }}</div>
          <div class="title txo2">
            {{ v.title }}
          </div>
          <div class="more">查看详情</div>
        </div>
      </div>
    </div>

    <div class="ad">
      <div class="img">
        <img src="@/static/images/index-ad-image-1.png" alt="" />
      </div>
      <div class="img">
        <img src="@/static/images/index-ad-image-2.png" alt="" />
      </div>
    </div>

    <div class="build">
      <div class="public-title">党建引领</div>

      <div class="body">
        <div class="left">
          <div class="swiper-container" id="build-swiper">
            <div class="swiper-wrapper">
              <div class="swiper-slide" v-for="(v, k) in build" :key="k">
                <img :src="v.coverImage" class="" />
              </div>
            </div>
            <!-- Add Pagination -->
            <div class="swiper-pagination" id="build-pagination"></div>
          </div>
        </div>

        <div class="right">
          <div class="list">
            <div class="item" v-for="(v, k) in build" :key="k" @click="$router.push('news-info?fromType=1&id=' + v.id)">
              <div class="title txo">
                {{ v.title }}
              </div>
              <div class="date">{{ v.createTime }}</div>
            </div>
          </div>
          <div class="more" @click="$router.push('build')">查看更多</div>
        </div>
      </div>
    </div>

    <div class="member">
      <div class="public-title">会员服务</div>
      <div class="con">
        <div class="mod">
          <div class="title">
            <div class="name">政策信息</div>
            <div class="more" @click="$router.push('service?active=0')">
              更多
            </div>
          </div>
          <div class="list">
            <div class="item" v-for="(v, k) in service1" :key="k" @click="$router.push('service-info?id=' + v.id)">
              <div class="name txo">
                {{ v.name }}
              </div>
              <div class="date">
                {{ v.redate }}
              </div>
            </div>
          </div>
        </div>
        <div class="mod">
          <div class="title">
            <div class="name">法律法规</div>
            <div class="more" @click="$router.push('service?active=1')">
              更多
            </div>
          </div>
          <div class="list">
            <div class="item" v-for="(v, k) in service2" :key="k" @click="$router.push('service-info?id=' + v.id)">
              <div class="name txo">
                {{ v.name }}
              </div>
              <div class="date">
                {{ v.redate }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="styles">
            <div class="public-title">商会风采</div>

            <div class="con">
                <div class="item main">
                    <img src="@/static/images/index-user0.png" alt="" />
                    <div class="name">李召春</div>
                    <div class="tag">商会会长</div>
                    <div class="info">东岱控股（山东）集团有限公司董事长</div>
                </div>
                <div class="other">
                    <div
                        class="items"
                        :style="
                            'width:' +
                            stylesScorllWidth +
                            'vw;animation-duration:' +
                            stylesScorllTime +
                            's;'
                        "
                    >
                        <div v-for="k in 3" :key="k">
                            <div
                                class="item"
                                v-for="kk in stylesItemCount"
                                :key="kk"
                            >
                                <img
                                    src="@/static/images/index-user.png"
                                    alt=""
                                />
                                <div class="name">李德强{{ kk }}</div>
                                <div class="tag">商会顾问</div>
                                <div class="info"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->

    <div class="styles new">
      <div class="public-title">商会风采</div>

      <div class="con">
        <div class="item main" @click="$router.push('member-info?id=' + leaderInfo.id)">
          <img :src="leaderInfo.image" alt="" />
          <div class="title">
            <div class="name">{{ leaderInfo.name }}</div>
            <span class="tag">{{ leaderInfo.leaderTypeName }}</span>
          </div>
          <div class="info">{{ leaderInfo.remark }}</div>
        </div>

        <div class="other">
          <div class="items" :style="'width:' +
            stylesScorllWidth +
            'vw;animation-duration:' +
            stylesScorllTime +
            's;'
            ">
            <div v-for="k in 3" :key="k">
              <div class="item" v-for="(vv, kk) in leaderList" :key="kk" @click="$router.push('member-info?id=' + vv.id)">
                <img :src="vv.image" alt="" />
                <div class="title">
                  <div class="name">{{ vv.name }}</div>
                  <span class="tag">{{ vv.leaderTypeName }}</span>
                </div>
                <div class="info">{{ vv.remark }}</div>
              </div>
            </div>
          </div>
        </div>

        <div class="other mobile">
          <div class="items" :style="'width:' +
            stylesScorllWidthMobile +
            'vw;animation-duration:' +
            stylesScorllTimeMobile +
            's;'
            ">
            <div v-for="k in 3" :key="k">
              <div class="item" v-for="(vv, kk) in leaderList" :key="kk" @click="$router.push('member-info?id=' + vv.id)">
                <img :src="vv.image" alt="" />
                <div class="title">
                  <div class="name">{{ vv.name }}</div>
                  <span class="tag">{{ vv.leaderTypeName }}</span>
                </div>
                <div class="info">{{ vv.remark }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="more" @click="$router.push('member')">查看更多</div>
    </div>

    <div class="product">
      <div class="public-title">产品展示</div>

      <div class="con">
        <div class="item" v-for="(v, k) in product" :key="k" @click="$router.push('product-info?id=' + v.id)">
          <img :src="v.image" alt="" />
          <div class="name txo">{{ v.name }}</div>
          <div class="info txo3">
            {{ getSimpleText(v.content) }}
          </div>
        </div>
      </div>
    </div>

    <div class="links">
      <div class="public-title">友情链接</div>
      <div class="con">
        <div class="item txo" v-for="(v, k) in links" :key="k" @click="toLink(v.url)">
          {{ v.name }}
        </div>
      </div>
    </div>

    <div class="float-btn btn1" @click="openUrl()">
      缴税 <br />
      办理
    </div>
    <div class="float-btn btn2" @click="$router.push('join')">
      入会 <br />
      申请
    </div>
    <div class="float-btn btn3 top" @click="topFunction()" v-if="topBackShow"></div>

    <Footer> </Footer>
  </div>
</template>

<script>
import Swiper, { Autoplay } from "swiper";

Swiper.use([Autoplay]);

import Header from "@/components/header.vue";
import Footer from "@/components/footer.vue";
export default {
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      stylesItemCount: 0,
      stylesScorllWidth: 0,
      stylesScorllTime: 0,

      stylesScorllWidthMobile: 0,
      stylesScorllTimeMobile: 0,

      bannerData: [],

      leaderList: [],
      leaderInfo: [],

      news: [],
      build: [],

      service1: [],
      service2: [],

      topBackShow: false,
      product: [],

      links: [
        {
          name: "东岱控股",
          url: "https://www.dongdaigroup.com",
        },
        {
          name: "华滨环联",
          url: "http://www.huanliangroup.cn",
        },
        {
          name: "雷德数控",
          url: "https://www.leadcnc.com",
        },
        { name: "辉瑞医学", url: "http://www.jnhrjh.com" },
        {
          name: "百事佳路豹",
          url: "http://sdc.landrover.com.cn",
        },
        { name: "昆腾商贸", url: "http://www.jnkunteng.com" },
        {
          name: "蓝贝思特教装",
          url: "http://www.sdlbst.com",
        },
        { name: "思明特", url: "https://www.smingte.com" },
        {
          name: "宝世达",
          url: "http://baoshidagroup.com",
        },
        { name: "凯莱电气", url: "http://www.sdkldq.com" },
        {
          name: "新佳涂料",
          url: "http://www.jnxinjia.com/index.html",
        },
        {
          name: "众联恒信",
          url: "http://www.jnzlsj.com",
        },
        {
          name: "泰圣源物流",
          url: "http://13969034888.cn.global-trade-center.com",
        },
        { name: "顺通石化", url: "http://sdstsh.cn" },
        {
          name: "普利思",
          url: "https://www.pulisi.com",
        },
        {
          name: "顺谊安装",
          url: "http://shunyi7942.51sole.com",
        },
        { name: "翰亚网络", url: "http://www.hywlkj.net" },
      ],
    };
  },
  created() {
    this.getBanner();
    this.getLeader();
    this.getNews();
    this.getService();
    this.getProduct();
  },
  mounted() {
    // this.myswiper();
    this.getBuild();
    window.onscroll = () => {
      if (
        document.body.scrollTop > 20 ||
        document.documentElement.scrollTop > 800
      ) {
        this.topBackShow = true;
      } else {
        this.topBackShow = false;
      }
    };
  },
  methods: {
    toLink(url) {
      window.open(url);
    },
    getProduct() {
      let data = {};
      data.starpro = 1;
      data.pageNum = 1;
      data.pageSize = 4;
      this.request.get(this.api.product.home, data).then((res) => {
        this.product = res.rows;
      });
    },
    getService() {
      let data = {};
      data.type = 0;
      data.pageNum = 1;
      data.pageSize = 8;
      this.request.get(this.api.service.list, data).then((res) => {
        this.service1 = res.rows;
      });

      data.type = 1;
      this.request.get(this.api.service.list, data).then((res) => {
        this.service2 = res.rows;
      });
      console.log(this.service);
    },
    buildSwiperInit() {
      new Swiper("#build-swiper", {
        autoplay: {
          delay: 2000,
        },
        loop: true,
        slidesPerView: "auto",
        centeredSlides: true,
        pagination: {
          el: "#build-pagination",
        },
      });
    },
    getBuild() {
      let data = {};
      data.pageNum = 1;
      data.pageSize = 3;
      data.type = 2;
      this.request.get(this.api.article.list, data).then((res) => {
        this.build = res.rows;
        this.$nextTick(() => {
          this.buildSwiperInit();
        });
      });
    },
    getNews() {
      let data = {};
      data.pageNum = 1;
      data.pageSize = 4;
      data.type = 1;
      this.request.get(this.api.article.list, data).then((res) => {
        this.news = res.rows;
      });
    },
    getLeader() {
      this.request.get(this.api.leader.list).then((res) => {
        console.log(res);
        for (let k in res) {
          let v = res[k];
          if (v.leaderTypeId == 3) {
            this.leaderInfo = v;
            res.splice(k, 1);
            break;
          }
        }
        this.$nextTick(() => {
          this.leaderList = res;
          this.stylesItemCount = this.leaderList.length;
          this.stylesScorllWidth = this.stylesItemCount * 12.45;
          this.stylesScorllTime = this.stylesItemCount * 2;

          this.stylesScorllWidthMobile = this.stylesItemCount * 12.45 * 3;
          this.stylesScorllTimeMobile = this.stylesItemCount * 2;
        });
      });
    },
    getBanner() {
      this.request.get(this.api.banner.list).then((res) => {
        this.bannerData = res;
        this.$nextTick(() => {
          this.bannerInit();
        });
      });
    },
    bannerInit() {
      new Swiper("#banner", {
        autoplay: {
          delay: 2000,
        },
        loop: true,
        slidesPerView: "auto",
        centeredSlides: true,

        pagination: {
          el: ".swiper-pagination",
        },
      });
    },
    toBannerInfo(row) {
      if (row.content) {
        this.$router.push("banner-info?id=" + row.id);
      }
    },

    myswiper: function () {
      new Swiper(".swiper-container", {
        autoplay: {
          delay: 2000,
        },
        loop: true,
        slidesPerView: "auto",
        centeredSlides: true,

        pagination: {
          el: ".swiper-pagination",
        },
      });
    },

    videoPlay() {
      document.getElementsByClassName("play")[0].style.display = "none";
      document.getElementsByClassName("poster")[0].style.display = "none";
      document.getElementById("descVideo").play();
    },
    topFunction() {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    },
    openUrl() {
      window.open(
        "https://etax.shandong.chinatax.gov.cn/enterprise/dzswjlogin/dzswj_login.jsp?type=wybs"
      );
    },

    getSimpleText(html) {
      var re1 = new RegExp("<.+?>", "g"); //匹配html标签的正则表达式，"g"是搜索匹配多个符合的内容
      var msg = html.replace(re1, ""); //执行替换成空字符
      re1 = new RegExp("&nbsp;", "g");
      msg = msg.replace(re1, ""); //执行替换成空字符
      return msg;
    },
  },
};
</script>

<style lang="scss" scoped>
@import url("../../node_modules/swiper/swiper.scss");

// * {
//     font-family: Microsoft YaHei;
// }
.index-page {
  // overflow-y: overlay;
  background-color: #fff;
  // overflow-x: overlay;
}

.banner {
  width: 100%;
  height: 41.67vw;
  overflow: hidden;

  .item {
    position: relative;

    img {
      position: absolute;

      &.text {
        width: 42.14vw;
        left: 15.52vw;
        top: 10.73vw;
      }

      &.bg {
        width: 100%;
        height: 41.67vw;
        top: 0;
        left: 0;
      }
    }
  }
}

.desc {
  max-width: 68.75vw; //68.75vw
  margin: 0 auto;
  padding-top: 6.72vw;
  padding-bottom: 5.94vw;
  display: flex;
  justify-content: space-between;

  .video-box {
    position: relative;
    width: 32.6vw;
    height: 18.39vw;
    min-width: 32.6vw;

    video {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
      width: 32.6vw;
      height: 18.39vw;
    }

    .poster {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 3;
      object-fit: cover;
      width: 32.6vw;
      height: 18.39vw;
      cursor: pointer;
    }

    .play {
      width: 4.27vw;
      height: 4.27vw;
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: 4;
      margin-left: -2.135vw;
      margin-top: -2.135vw;
      cursor: pointer;
    }
  }

  .desc-box {
    width: 31.25vw;
    height: 18.39vw;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    img {
      width: 26.98vw;
      height: 4.58vw;
      margin-left: auto;
    }

    >div {
      text-indent: 2em;
      font-size: 1.04vw;
      color: #5c5c5c;
      line-height: 2.08vw;
    }
  }
}

.news {
  width: 100%;
  height: 41.67vw;
  background-image: url(@/static/images/index-news-bg.png);
  background-size: 100% 100%;

  .public-title {
    padding-top: 4.53vw;
    margin-bottom: 4.64vw;
  }

  .list {
    width: 68.75vw;
    margin: 0 auto;
    display: flex;
    // flex-wrap: wrap;
    justify-content: space-between;

    .item {
      transition: all 0.3s;
      background-color: #fff;
      width: 16.15vw;
      height: 21.98vw;
      cursor: pointer;
      // margin-right: 1.38vw;
      // &:nth-child(4n) {
      //     margin-right: 0;
      // }
      box-sizing: border-box;
      padding: 0.68vw 0.625vw;

      img {
        width: 14.9vw;
        height: 9.95vw;
        margin-bottom: 0.89vw;
      }

      .day {
        font-size: 1.88vw;
        line-height: 1.88vw;
        color: #bebebe;
        font-weight: bold;
        margin-bottom: 0.47vw;
      }

      .year {
        font-size: 0.94vw;
        line-height: 0.94vw;
        color: #6b6b6b;
        margin-bottom: 1.36vw;
      }

      .title {
        line-height: 1.5vw;
        height: 2.8vw;
        font-size: 0.9vw;
        color: #040000;
        font-weight: 500;
        margin-bottom: 1vw;
      }

      .more {
        border-top: 0.05vw solid #e0e0e0;
        font-size: 0.8vw;
        line-height: 0.8vw;
        color: #6b6b6b;
        padding-top: 0.73vw;
      }

      &:hover {
        transform: translateY(-0.52vw);
        box-shadow: 0 0.1vw 1.51vw 0 rgba(3, 32, 75, 0.16);

        .day,
        .title {
          color: #0c4baa;
        }
      }
    }
  }
}

.ad {
  padding-top: 2.08vw;
  width: 68.75vw;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;

  .img {
    width: 33.44vw;
    height: 6.98vw;
    overflow: hidden;

    img {
      width: 33.44vw;
      height: 6.98vw;
      transition: all 0.3s;

      &:hover {
        transform: scale(1.05);
      }
    }
  }
}

.build {
  margin-top: 4.9vw;
  padding-bottom: 6.51vw;

  .body {
    width: 68.75vw;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    height: 21.82vw;
    margin-top: 4.11vw;

    .left,
    .swiper-slide,
    img {
      width: 32.71vw;
      height: 21.82vw;
    }

    .left {
      min-width: 32.71vw;
      overflow: hidden;
    }

    .right {
      width: 31.04vw;
      height: 21.82vw;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .list {
        padding-top: 0.63vw;

        .item {
          cursor: pointer;
          margin-bottom: 2.08vw;
          border-bottom: 0.05vw solid #d3d8ed;
          padding-bottom: 1.56vw;

          >* {
            transition: all 0.3s;
          }

          &:last-child {
            margin-bottom: 0;
          }

          .title {
            width: 31.04vw;
            font-size: 1.04vw;
            color: #040000;
            line-height: 1.04vw;
            margin-bottom: 0.73vw;
            font-weight: bold;
          }

          .date {
            font-size: 0.94vw;
            color: #6b6b6b;
            line-height: 0.94vw;
          }

          &:hover {

            .title,
            .date {
              text-indent: 1em;
              opacity: 0.8;
            }
          }
        }
      }

      .more {
        width: 8.33vw;
        height: 2.08vw;
        line-height: 2.08vw;
        text-align: center;
        color: #fff;
        background-color: #226ad5;
        border-radius: 1.2vw;
        font-size: 0.94vw;
        cursor: pointer;

        &:hover {
          opacity: 0.8;
        }
      }
    }
  }
}

.member {
  background-color: #f8fcff;
  padding-top: 4.22vw;
  padding-bottom: 4.64vw;

  .con {
    padding-top: 3.85vw;
    width: 68.75vw;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;

    .mod {
      width: 32.08vw;

      .title {
        height: 3.7vw;
        line-height: 3.7vw;
        display: flex;
        justify-content: space-between;
        background-color: #c0d7fa;
        border-bottom: 0.16vw solid #3f82dd;
        margin-bottom: 0.88vw;

        .name {
          font-size: 1.7vw;
          color: #040000;
          padding-left: 1.04vw;
        }

        .more {
          font-size: 1.04vw;
          color: #acacac;
          margin-right: 0.83vw;
          cursor: pointer;
        }
      }

      .list {
        margin-left: 1.25vw;

        .item {
          display: flex;
          justify-content: space-between;
          padding: 0.8vw 0;
          border-bottom: 0.05vw solid #d3d8ed;
          cursor: pointer;

          .name {
            width: 21vw;
            font-size: 1.1vw;
            line-height: 1.25vw;
            color: #2b2b2b;
            transition: all 0.3s;
          }

          .date {
            color: #747474;
            font-size: 1.1vw;
          }

          &:hover {
            .name {
              margin-left: 1vw;
              opacity: 0.8;
            }
          }
        }
      }
    }
  }
}

// .styles {
//     padding-top: 5.1vw;
//     padding-bottom: 5.52vw;
//     .con {
//         width: 68.75vw;
//         margin: 0 auto;
//         padding-top: 7.19vw;
//         display: flex;
//         .item {
//             width: 11.09vw;
//             img {
//                 width: 11.09vw;
//                 height: 11.09vw;
//             }
//             div {
//                 text-align: center;
//             }
//             .name {
//                 font-size: 1.35vw;
//                 font-weight: bold;
//                 color: #343434;
//                 line-height: 1.35vw;
//                 margin-top: 2.19vw;
//                 margin-bottom: 0.78vw;
//             }
//             .tag {
//                 color: #565656;
//                 font-size: 1.25vw;
//                 line-height: 1.25vw;
//                 margin-bottom: 0.65vw;
//             }
//             .info {
//                 font-size: 0.94vw;
//                 color: #565656;
//                 line-height: 1.09vw;
//             }
//         }
//         .main {
//             margin-right: 3.44vw;
//         }
//         .other {
//             width: 54.22vw;
//             overflow: hidden;
//             position: relative;
//             .items {
//                 animation: scorll 10s linear infinite;
//                 position: absolute;
//                 display: flex;
//                 > div {
//                     display: flex;
//                 }
//                 .item {
//                     cursor: pointer;
//                     margin-right: 3.28vw;
//                     &:hover {
//                         // animation-play-state: paused;
//                         opacity: 0.9;
//                     }
//                 }
//                 &:hover {
//                     animation-play-state: paused;
//                 }
//             }
//         }
//     }
// }
@keyframes scorll {
  0% {
    transform: translateX(0%);
  }

  100% {
    transform: translateX(-100%);
  }
}

.styles.new {
  padding-top: 5.1vw;
  padding-bottom: 3.8vw;

  .public-title {
    margin-bottom: 2.81vw;
  }

  .item {
    width: 11.3vw;
    height: 23.3vw;
    color: #565656;
    cursor: pointer;
    margin-right: 1.15vw;

    &:hover {
      background-color: #2777ce;
      color: #fff !important;

      * {
        color: #fff !important;
      }
    }

    img {
      height: 16.56vw;
      width: 11.3vw;
      margin-bottom: 0.83vw;
      object-fit: cover;
    }

    .title {
      display: flex;
      vertical-align: text-bottom;
      margin-bottom: 0.93vw;
      padding: 0 0.63vw;

      .name {
        font-size: 1.25vw;
        margin-right: 0.52vw;
        font-weight: bold;
        line-height: 1.25vw;
        color: #343434;
      }

      .tag {
        font-size: 1.04vw;
        position: relative;
        bottom: 0;
      }
    }

    .info {
      padding: 0 0.63vw;
      line-height: 0.94vw;
      font-size: 0.83vw;
    }
  }

  .con {
    display: flex;
    width: 68.75vw;
    margin: 0 auto;
    padding-bottom: 3.39vw;

    .other.mobile {
      display: none;
    }

    .other {
      display: flex;
      overflow: hidden;

      .items {
        animation: scorll 10s linear infinite;
        display: flex;

        >div {
          display: flex;
        }

        &:hover {
          animation-play-state: paused;
        }
      }
    }
  }

  .more {
    width: 8.33vw;
    height: 2.08vw;
    line-height: 2.08vw;
    text-align: center;
    border-radius: 2vw;
    background-color: #dee3ea;
    margin: 0 auto;
    font-size: 0.9vw;
    color: #7a7a81;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }
  }
}

.product {
  width: 100%;
  background-image: url(@/static/images/index-product-bg.png);
  background-size: 100% 100%;
  padding-top: 4.43vw;
  padding-bottom: 5.1vw;
  margin-bottom: 5.26vw;

  .public-title {
    color: #fff;

    &::after {
      content: "";
      width: 2.97vw;
      height: 0.31vw;
      background-color: #fff;
      border-radius: 0.3vw;
      position: absolute;
      left: 50%;
      margin-left: -1.485vw;
      bottom: 0;
    }
  }

  .con {
    padding-top: 3.54vw;
    display: flex;
    width: 68.75vw;
    margin: 0 auto;
    justify-content: space-between;

    .item {
      width: 16.15vw;
      height: 24.79vw;
      background-color: #fff;
      cursor: pointer;
      box-shadow: 0 0.57vw 1.09vw 0 rgba(16, 58, 103, 0.2);

      transition: transform 0.3s;

      &:hover {
        transform: translateY(-0.83vw);

        .name {
          color: #3176bb;
        }
      }

      img {
        width: 16.15vw;
        height: 13.92vw; //11.88vw
        margin-bottom: 2.14vw;
        object-fit: cover;
      }

      .name {
        margin: 0 1.82vw;
        font-size: 1.04vw;
        line-height: 1.8vw;
        font-weight: bold;
        color: #2e2f2f;
        margin-bottom: 1.6vw;
      }

      .info {
        margin: 0 1.67vw;
        line-height: 1.64vw;
        font-size: 0.96vw;
        color: #7f7f7f;
      }
    }
  }
}

.links {
  padding-bottom: 6.72vw;

  .con {
    padding-top: 4.11vw;
    display: flex;
    width: 68.85vw;
    margin: 0 auto;
    flex-wrap: wrap;

    .item {
      width: 10.95vw;
      height: 3.23vw;
      line-height: 3.23vw;
      text-align: center;
      background-color: #d0e8ff;
      font-size: 1.25vw;
      color: #030000;
      margin-right: 0.63vw;
      margin-bottom: 0.68vw;
      cursor: pointer;
      padding: 0 1vw;
      box-sizing: border-box;

      &:nth-child(6n) {
        margin-right: 0;
      }

      &:hover {
        box-shadow: 0 0.1vw 1.51vw 0 rgba(3, 32, 75, 0.16);
      }
    }
  }
}

.float-btn {
  width: 4.74vw;
  height: 4.74vw;
  position: fixed;
  right: 0.89vw;
  z-index: 9999;
  border-radius: 3vw;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  color: #fff;
  font-size: 1.04vw;
  line-height: 1.25vw;
  cursor: pointer;
  transition: transform 0.2s;

  &:hover {
    transform: scale(1.05);
  }

  &.btn1 {
    top: 45vh;
    background-image: url(@/static/images/index-float-bg-1.png);
    background-size: 100% 100%;
  }

  &.btn2 {
    top: calc(45vh + 4.74vw + 1.46vw);
    background-image: url(@/static/images/index-float-bg-2.png);
    background-size: 100% 100%;
  }

  &.btn3 {
    top: calc(45vh + 4.74vw + 4.74vw + 1.46vw + 1.46vw);
    background-image: url(@/static/images/index-float-top.png);
    background-size: 104.5% 104.5%;
    background-position: 50% 50%;
  }
}
</style>

<style lang="scss" scoped>
// 移动端响应式样式
@media screen and (max-width: 600px) {
  .banner {
    height: 60vw;

    .item {
      img {
        object-fit: cover;

        &.text {
          width: 42.14vw * 1.3;
          left: 10vw;
          top: 20.73vw;
        }

        &.bg {
          height: 60vw;
        }
      }
    }
  }

  .desc {
    width: 92vw;
    margin: 0 4vw;
    flex-direction: column-reverse;
    flex-wrap: wrap;
    padding: 4vw 0 !important;

    .video-box {
      width: 92vw;
      height: 50vw;
      overflow: hidden;

      video {
        width: 92vw;
        height: 50vw;
      }

      .poster {
        width: 92vw;
        height: 50vw;
      }

      .play {
        width: 4.27vw * 3;
        height: 4.27vw * 3;
        margin-left: -2.135vw * 3;
        margin-top: -2.135vw * 3;
      }
    }

    .desc-box {
      width: 92vw;
      height: 18.39vw * 2.7;
      margin-top: 4vw;
      margin-bottom: 4vw;

      img {
        width: 26.98vw * 3;
        height: 4.58vw * 3;
        margin-left: auto;
      }

      >div {
        font-size: 1.04vw * 3;
        line-height: 2.08vw * 3;
      }
    }
  }

  .news {
    height: auto !important;
    background-size: cover;
    padding-bottom: 5vw;

    .public-title {
      padding-top: 4.53vw * 2;
      margin-bottom: 4.64vw * 2;
    }

    .list {
      width: 92vw;
      margin: 0 4vw;
      display: flex;
      flex-wrap: wrap;
      justify-content: row;

      .item {
        width: 45vw;
        height: auto;
        margin-bottom: 2vw;
        padding: 2vw;

        img {
          width: 41vw;
          height: 27.7vw;
          margin-bottom: 2vw;
          object-fit: cover;
        }

        .day {
          font-size: 1.88vw * 3;
          line-height: 1.88vw * 3;
          margin-bottom: 0.47vw * 3;
        }

        .year {
          font-size: 0.94vw * 3;
          line-height: 0.94vw * 3;
          margin-bottom: 1.36vw * 3;
        }

        .title {
          line-height: 1.25vw * 3;
          height: 2.5vw * 3;
          font-size: 1.04vw * 3;
          margin-bottom: 1vw * 3;
        }

        .more {
          font-size: 0.94vw * 3;
          line-height: 0.94vw * 3;
          padding-top: 0.73vw * 3;
        }
      }
    }
  }

  .ad {
    padding-top: 2.08vw * 3;
    width: 92vw;
    margin: 0 4vw;
    display: flex;
    flex-wrap: wrap;

    .img {
      width: 92vw;
      height: auto;
      margin-bottom: 4vw;

      img {
        width: 92vw;
        height: auto;
      }
    }
  }

  .build {
    .body {
      width: 92vw;
      margin: 0 4vw;
      display: flex;
      flex-wrap: wrap;
      height: auto;
      margin-top: 4.11vw;

      .left,
      .swiper-slide,
      img {
        width: 92vw;
        height: 62vw;
      }

      .right {
        width: 92vw;
        height: auto;
        margin-top: 4vw;

        .list {
          padding-top: 0.63vw * 3;

          .item {
            margin-bottom: 1.56vw * 2;
            border-bottom: 0.05vw solid #d3d8ed;
            padding-bottom: 1.56vw * 2;

            .title {
              width: 31.04vw * 3;
              font-size: 1.04vw * 3;
              line-height: 1.04vw * 3;
              margin-bottom: 0.73vw * 3;
            }

            .date {
              font-size: 0.94vw * 3;
              line-height: 0.94vw * 3;
            }
          }
        }

        .more {
          width: 8.33vw * 3;
          height: 2.08vw * 3;
          line-height: 2.08vw * 3;
          border-radius: 1.2vw * 3;
          font-size: 0.94vw * 3;
          margin-top: 6vw;
          position: relative;
          left: 50%;
          margin-left: -8.33vw * 1.5;
        }
      }
    }
  }

  .member {
    padding-top: 10vw;

    .con {
      padding-top: 3.85vw * 3;
      width: 92vw;
      margin: 0 4vw;
      flex-wrap: wrap;

      .mod {
        width: 92vw;
        margin-bottom: 4vw;

        .title {
          height: 3.7vw * 3;
          line-height: 3.7vw * 3;
          margin-bottom: 0.88vw * 3;

          .name {
            font-size: 1.7vw * 3;
            padding-left: 1.04vw * 3;
          }

          .more {
            font-size: 1.04vw * 3;
            margin-right: 0.83vw * 3;
          }
        }

        .list {
          margin-left: 1.25vw * 3;
          width: 84vw;

          .item {
            padding: 1.46vw * 3 0;

            .name {
              width: 21vw * 2.4;
              font-size: 1.25vw * 3;
              line-height: 1.25vw * 3;
            }

            .date {
              font-size: 1.25vw * 3;
            }
          }
        }
      }
    }
  }

  .styles.new {
    padding-top: 12vw;
    padding-bottom: 10vw;

    .item {
      width: 11.3vw * 3;
      height: 23.3vw * 3;
      margin-right: 1.15vw * 3;

      img {
        height: 16.56vw * 3;
        width: 11.3vw * 3;
        margin-bottom: 0.83vw * 3;
      }

      .title {
        margin-bottom: 0.93vw * 3;
        padding: 0 0.63vw * 3;

        .name {
          font-size: 1.25vw * 3;
          margin-right: 0.52vw * 3;
          line-height: 1.25vw * 3;
        }

        .tag {
          font-size: 1.04vw * 3;
        }
      }

      .info {
        padding: 0 0.63vw * 3;
        line-height: 0.94vw * 3;
        font-size: 0.83vw * 3;
      }
    }

    .con {
      width: 92vw;
      margin: 0 4vw;
      padding-bottom: 3.39vw * 3;
      flex-wrap: wrap;

      .main {
        position: relative;
        left: 50%;
        margin-left: -11.3vw * 1.5;
        margin-top: 10vw;
      }

      .other {
        display: none !important;
      }

      .other.mobile {
        width: 92vw;
        display: flex !important;
        margin-top: 10vw;
      }
    }

    .more {
      width: 8.33vw * 3;
      height: 2.08vw * 3;
      line-height: 2.08vw * 3;
      border-radius: 2vw * 3;
      font-size: 0.94vw * 3;
    }
  }

  .product {
    background-size: cover;
    height: auto !important;
    padding-top: 12vw;

    .public-title {
      &::after {
        content: "";
        width: 2.97vw * 3;
        height: 0.31vw * 3;
        background-color: #fff;
        border-radius: 0.3vw * 3;
        position: absolute;
        left: 50%;
        margin-left: -1.485vw * 3;
        bottom: 0;
      }
    }

    .con {
      width: 92vw;
      height: auto !important;
      margin: 0 4vw;
      justify-content: start;
      flex-wrap: wrap;
      margin-top: 6vw;

      .item {
        width: 45vw;
        height: auto !important;
        padding-bottom: 3vw;
        margin-right: 2vw;
        margin-bottom: 2vw;

        &:nth-child(2n) {
          margin-right: 0;
        }

        img {
          width: 45vw;
          height: 33vw;
          margin-bottom: 2.14vw * 3;
        }

        .name {
          margin: 0 1.82vw * 3;
          font-size: 1.56vw * 3;
          line-height: 1.56vw * 3;
          margin-bottom: 1.84vw * 3;
        }

        .info {
          margin: 0 1.67vw * 3;
          line-height: 1.64vw * 3;
          font-size: 1.04vw * 3;
        }
      }
    }
  }

  .links {
    .con {
      width: 92vw;
      margin: 0 4vw;
      flex-wrap: wrap;

      .item {
        width: 29vw;
        height: 3.23vw * 3;
        line-height: 3.23vw * 3;
        font-size: 1.25vw * 3;
        margin-right: 2vw;
        margin-bottom: 2vw;

        &:nth-child(6n) {
          margin-right: 2vw !important;
        }

        &:nth-child(3n) {
          margin-right: 0 !important;
        }
      }
    }
  }

  .float-btn {
    width: 4.74vw * 2.5;
    height: 4.74vw * 2.5;
    border-radius: 3vw * 2.5;
    right: 0.89vw * 2.5;
    font-size: 1.04vw * 2.5;
    line-height: 1.25vw * 2.5;
    opacity: 0.8;

    &.btn2 {
      top: calc(45vh + 4.74vw * 2.5 + 1.46vw * 2.5);
    }

    &.btn3 {
      top: calc(45vh + 4.74vw * 2.5 + 4.74vw * 2.5 + 1.46vw * 2.5 + 1.46vw * 2.5);
    }
  }
}</style>
