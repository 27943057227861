<template>
  <div class="header">
    <div class="line">
      <img src="@/static/images/nav-logo.png" alt="" class="logo" />
      <img src="@/static/images/nav-header-text.png" alt="" class="topic" />
      <div class="search-box" v-show="activeIndex != 9">
        <div class="input">
          <input type="text" placeholder="请输入关键字" v-model="keyword" />
        </div>
        <div class="btn" @click="($event) => toQuery()">
          <img src="@/static/images/icon-search.png" alt="" />
        </div>
      </div>
    </div>
    <div class="nav">
      <div class="nav-main">
        <div class="item">
          <div
            class="name"
            :style="
              activeIndex == 1
                ? 'background-color: #d5ebf5;color: #1a66b9;font-weight: bold;'
                : ''
            "
            @click="$router.push('/')"
          >
            首页
          </div>
          <div class="list"></div>
        </div>
        <div class="item">
          <div
            class="name"
            :style="
              activeIndex == 2
                ? 'background-color: #d5ebf5;color: #1a66b9;font-weight: bold;'
                : ''
            "
            @click="$router.push('desc')"
          >
            商会概况
          </div>
          <div class="list">
            <!-- <div>商户简介</div>
                        <div>商会章程</div>
                        <div>组织架构</div> -->
          </div>
        </div>
        <div class="item">
          <div
            class="name"
            :style="
              activeIndex == 3
                ? 'background-color: #d5ebf5;color: #1a66b9;font-weight: bold;'
                : ''
            "
            @click="$router.push('build')"
          >
            党建引领
          </div>
          <div class="list"></div>
        </div>
        <div class="item">
          <div
            class="name"
            :style="
              activeIndex == 4
                ? 'background-color: #d5ebf5;color: #1a66b9;font-weight: bold;'
                : ''
            "
            @click="$router.push('news')"
          >
            商会要闻
          </div>
          <div class="list"></div>
        </div>
        <div class="item">
          <div
            class="name"
            :style="
              activeIndex == 5
                ? 'background-color: #d5ebf5;color: #1a66b9;font-weight: bold;'
                : ''
            "
            @click="$router.push('resource')"
          >
            资源中心
          </div>
          <div class="list"></div>
        </div>
        <div class="item">
          <div
            class="name"
            :style="
              activeIndex == 6
                ? 'background-color: #d5ebf5;color: #1a66b9;font-weight: bold;'
                : ''
            "
            @click="$router.push('member')"
          >
            领导风采
          </div>
          <div class="list"></div>
        </div>
        <div class="item">
          <div
            class="name"
            :style="
              activeIndex == 8
                ? 'background-color: #d5ebf5;color: #1a66b9;font-weight: bold;'
                : ''
            "
            @click="$router.push('service')"
          >
            商会服务
          </div>
          <div class="list"></div>
        </div>
        <div class="item">
          <div
            class="name"
            :style="
              activeIndex == 7
                ? 'background-color: #d5ebf5;color: #1a66b9;font-weight: bold;'
                : ''
            "
            @click="$router.push('contact')"
          >
            联系我们
          </div>
          <div class="list"></div>
        </div>
      </div>
    </div>

    <div class="nav-mobile">
      <img src="@/static/images/nav-logo-2.png" alt="" class="logo" />
      <i class="more el-icon-more" @click="open = true"></i>
    </div>
    <el-drawer
      :visible="open"
      direction="rtl"
      @close="close()"
      size="40%"
      :with-header="false"
    >
      <div class="nav-mobile-list">
        <div @click="$router.push('/')">首页</div>
        <div @click="$router.push('desc')">商会概况</div>
        <div @click="$router.push('build')">党建引领</div>
        <div @click="$router.push('news')">商会要闻</div>
        <div @click="$router.push('resource')">资源中心</div>
        <div @click="$router.push('member')">领导风采</div>
        <div @click="$router.push('contact')">联系我们</div>
        <div @click="$router.push('service')">商会服务</div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import Vue from "vue";
import { Drawer } from "element-ui";
Vue.use(Drawer);
export default {
  data() {
    return {
      open: false,
      keyword: "",
    };
  },
  props: {
    // 选中项
    activeIndex: {
      type: Number,
      default: 0,
    },
  },
  created() {
    window.addEventListener("keydown", this.handkeyCode, true); //开启监听键盘按下事件
  },
  methods: {
    close() {
      this.open = false;
    },
    toQuery() {
      let key = this.keyword;
      if (key) {
        this.$router.push({
          path: "/query",
          query: {
            keyword: this.keyword,
          },
        });
      }
    },
    // 回车和空格键提交右侧信息
    handkeyCode(e) {
      //  console.log(e); // 打印出按键后的信息
      if (e.keyCode === 13) {
        console.log("触发了回车/空格键");
        //触发登录/注册方法
        this.toQuery();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "element-ui/lib/theme-chalk/index.css";
.header {
  position: sticky;
  top: -10.63vw;
  width: 100%;
  z-index: 10000;
  .line {
    background-color: #fff;
    width: 100%;
    height: 10.63vw;
    background-image: url(@/static/images/nav-header-bg.png);
    background-size: 100% 100%;
    display: flex;
    position: relative;
    z-index: 10003;
    .logo {
      width: 30.1vw;
      height: 5.16vw;
      margin-left: 15.63vw;
      margin-top: 3.59vw;
      margin-right: 3.8vw;
    }
    .topic {
      width: 17.08vw;
      height: 3.13vw;
      margin-top: 4.58vw;
    }
    .search-box {
      margin-left: 3.18vw;
      margin-top: 5.36vw;
      width: 14.58vw;
      height: 1.82vw;
      box-sizing: border-box;
      // border: 0.05vw solid #70a7d9;
      display: flex;
      .input {
        background-color: #fff;
        height: 1.6vw;
        margin-top: 0.05vw;
        width: 12.66vw;
        position: relative;
        input {
          position: absolute;
          top: 0;
          left: 0;
          background-color: rgba($color: #000000, $alpha: 0);
          border: none;
          height: 1.6vw;
          line-height: 1.6vw;
          outline: none;
          padding-left: 0.7vw;
          font-size: 0.83vw;
          color: #333;
          &::placeholder {
            /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: #dedede;
            font-size: 0.83vw;
            line-height: 1.6vw;
          }

          &:-ms-input-placeholder {
            /* Internet Explorer 10-11 */
            color: #dedede;
            font-size: 0.83vw;
            line-height: 1.6vw;
          }

          &::-ms-input-placeholder {
            /* Microsoft Edge */
            color: #dedede;
            font-size: 0.83vw;
            line-height: 1.6vw;
          }
        }
      }
      .btn {
        width: 1.93vw;
        height: 1.7vw;
        background-color: #70a7d9;
        position: relative;
        img {
          width: 1.25vw;
          height: 1.2vw;
          margin: 0.31vw;
          position: absolute;
          top: 0;
          left: 0;
        }
      }
    }
  }
  .nav {
    width: 100%;
    height: 3.96vw;
    background-color: #1a66b9;
    color: #ffffff;
    display: flex;
    justify-content: center;
    .nav-main {
      width: 68.75vw;
      height: 3.96vw;
      display: flex;
    }
    .item {
      height: 3.96vw;
      text-align: center;
      flex: 1;
      cursor: pointer;
      position: relative;
      &:hover {
        .name {
          background-color: #d5ebf5;
          color: #1a66b9;
          font-weight: bold;
        }
        .list {
          // bottom: -300%;
          // top: 100%;
          transform: scaleY(1);
        }
      }
      .name {
        width: 100%;
        height: 3.96vw;
        line-height: 3.94vw;
        position: relative;
        z-index: 10003;
        background-color: #1a66b9;
        font-size: 1.26vw;
      }
      .list {
        z-index: 10002;
        position: absolute;
        width: 100%;
        transition: all 0.2s;
        top: 100%;
        // top: 0;
        // top: -300%;
        bottom: 0;
        left: 0;
        // display: none;
        transform: scaleY(0);
        transform-origin: top;
        background-color: #fff;
        > div {
          font-size: 1.15vw;
          width: 100%;
          height: 3.13vw;
          line-height: 3.13vw;
          background-color: #1a66b9;
          &:nth-child(2n) {
            background-color: #327ac9;
          }
        }
      }
    }
  }
  .nav-mobile {
    width: 100vw;
    height: 12vw;
    background-color: #1a66b9;
    color: #fff;
    line-height: 12vw;
    display: none;
    .logo {
      height: 8vw;
      margin-top: 2vw;
      margin-left: 2vw;
      margin-right: auto;
    }
    .more {
      font-size: 6vw;
      margin-right: 2vw;
      margin-top: 3vw;
    }
  }
  .nav-mobile-list {
    width: 40vw;
    background-color: #1a66b9;
    color: #fff;
    height: 100%;
    padding-top: 20vw;
    box-sizing: border-box;
    > div {
      text-align: center;
      font-size: 1.15vw * 3;
      width: 40vw;
      height: 3.13vw * 4;
      line-height: 3.13vw * 4;
      background-color: #1a66b9;
      &:nth-child(2n) {
        background-color: #327ac9;
      }
    }
  }
}
</style>

<style lang="scss" scoped>
// 移动端响应式样式
@media screen and (max-width: 600px) {
  .header {
    top: 0;
  }
  .line,
  .nav {
    display: none !important;
  }
  .nav-mobile {
    display: flex !important;
  }
}
</style>
