// var host = "https://www.panyx.top/crmsv";
// var imgHost = "https://www.panyx.top/";
var host = process.env.VUE_APP_BASE_API;
// var imgHost = "https://crm.dongdaigroup.com/";
export default {
    // imgHost,
    // 首页轮播图
    banner: {
        list: host + '/busi/api/banner',
        info: host + '/busi/api/banner/'
    },
    article: {
        list: host + "/busi/api/article",
        info: host + "/busi/api/article/"
    },
    leader: {
        type: host + "/busi/api/leaderType",
        list: host + "/busi/api/leader",
        info: host + "/busi/api/leader/"
    },
    enterprise: {
        info: host + "/busi/api/enterprise/",
        type: host + "/busi/api/enterpriseType",
        list: host + "/busi/api/enterprise"
    },
    join: host + "/busi/api/apply/add",
    product: {
        enterprise: host + "/busi/api/productType",
        info: host + "/busi/api/product/",
        home: host + "/busi/api/product"
    },
    service: {
        list: host + "/busi/api/policy",
        info: host + "/busi/api/policy/"
    },
    query: {
        list: host + "/busi/api/query"
    }
}