/**
 * request.js
 * 通过promise对axios做二次封装，针对用户端参数，做灵活配置
 */
import { Message, Loading } from 'element-ui';
import instance from './interceptor'
import qs from 'qs';
import md5 from "../static/js/md5.js";
/**
 * 核心函数，可通过它处理一切请求数据，并做横向扩展
 * @param {url} 请求地址
 * @param {params} 请求参数
 * @param {options} 请求配置，针对当前本次请求；
 * @param loading 是否显示loading
 * @param mock 本次是否请求mock而非线上
 * @param error 本次是否显示错误
 */
function request(url, params, options = { loading: true, mock: false, error: true }, method) {
    let loadingInstance;
    // 请求前loading
    if (options.loading) loadingInstance = Loading.service();
    return new Promise((resolve, reject) => {
        let data = {}



        // 签名
        let timestamp = Date.now();
        timestamp = timestamp.toString();
        // timestamp = timestamp.substr(0, timestamp.length - 3);
        let sign = signature(params, timestamp);




        // get请求使用params字段
        if (method == 'get') {
            if (url.indexOf("?") == -1) {
                url += "?sign=" + sign + "&timestamp=" + timestamp
            } else {
                url += "&sign=" + sign + "&timestamp=" + timestamp
            }
            data = { params }
        }
        // post请求使用data字段
        if (method == 'post') {

            params.sign = sign;
            params.timestamp = timestamp;
            params = qs.stringify(params);

            data = { data: params };
        }
        // 通过mock平台可对局部接口进行mock设置
        if (options.mock) url = 'http://www.mock.com/mock/xxxx/api';
        instance({
            url,
            method,
            ...data
        }).then((res) => {
            // 此处作用很大，可以扩展很多功能。
            // 比如对接多个后台，数据结构不一致，可做接口适配器
            // 也可对返回日期/金额/数字等统一做集中处理
            // console.log(res);
            if (res.code === 200) {
                resolve(res.data);
            } else {
                // 通过配置可关闭错误提示
                if (options.error) Message.error(res.message);
                reject(res);
            }
        }).catch((error) => {
            console.log(error);
            // Message.error(error.message)
        }).finally(() => {
            loadingInstance.close();
        })
    })
}



function signature(data = {}, timestamp) {
    data = JSON.parse(JSON.stringify(data));
    let str = [];
    data.timestamp = timestamp;
    if (Object.keys(data).length) {
        data = objKeySort(data);
        for (let k in data) {
            str.push(k + '=' + data[k]);
        }
    }
    // str.push("timestamp=" + timestamp + "D8MKZ629")
    // console.log("sign:", str)
    str = str.join("&");
    // console.log("sign:", str)
    return md5.hex_md5(str + "D8MKZ629")
}


function objKeySort(obj) { //排序的函数
    var newkeyArr = Object.keys(obj).sort();
    // var newkeyArr = Object.keys(obj).sort().reverse();//倒序
    //先用Object内置的keys()方法获取待排序对象的属性名，再利用Array中的sort方法对获取的属性名进行排序，newkeyArr是一个数组
    var newObj = {}; //创建一个新的对象，用于存放排好序的键值对
    for (var i = 0, len = newkeyArr.length; i < len; i++) { //遍历newkeyArr数组
        newObj[newkeyArr[i]] = obj[newkeyArr[i]]; //向新创建的对象中按照排好的顺序依次增加键值对
    }
    return newObj; //返回排好序的新对象
}



// 封装GET请求
function get(url, params, options) {
    return request(url, params, options, 'get')
}
// 封装POST请求
function post(url, params, options) {
    return request(url, params, options, 'post')
}
export default {
    get,
    post
}