import Vue from 'vue'
import Router from 'vue-router'
import index from '../views/index.vue'

Vue.use(Router)

const routes = [
  {
    path: '/',
    name: 'index',
    component: index,
    meta: {
      title: '王舍人街道商会',
      keepAlive: true
    },
  },
  {
    path: '/member',
    name: 'member',
    component: () => import('../views/member.vue')
  },
  {
    path: '/member-info',
    name: 'member-info',
    component: () => import('../views/member-info.vue')
  },
  {
    path: '/desc',
    name: 'desc',
    component: () => import('../views/desc.vue')
  },
  {
    path: '/build',
    name: 'build',
    component: () => import('../views/build.vue')
  },
  {
    path: '/resource',
    name: 'resource',
    component: () => import('../views/resource.vue')
  },
  {
    path: '/news',
    name: 'news',
    component: () => import('../views/news.vue')
  },
  {
    path: '/news-info',
    name: 'news-info',
    component: () => import('../views/news-info.vue')
  },
  {
    path: '/service',
    name: 'service',
    component: () => import('../views/service.vue')
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import('../views/contact.vue')
  },
  {
    path: '/join',
    name: 'join',
    component: () => import('../views/join.vue')
  },
  {
    path: '/banner-info',
    name: 'banner-info',
    component: () => import('../views/banner-info.vue')
  },
  {
    path: '/enterprise-info',
    name: 'enterprise-info',
    component: () => import('../views/enterprise-info.vue')
  },
  {
    path: '/product-info',
    name: 'product-info',
    component: () => import('../views/product-info.vue')
  },
  {
    path: '/service-info',
    name: 'service-info',
    component: () => import('../views/service-info.vue')
  },
  {
    path: '/query',
    name: 'query',
    component: () => import('../views/query.vue')
  }
]

const router = new Router({
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})




router.beforeEach((to, from, next) => { //beforeEach是router的钩子函数，在进入路由前执行

  if (to.meta.title) { //判断是否有标题

    document.title = to.meta.title

  }

  next() //执行进入路由，如果不写就不会进入目标页

})

export default router
