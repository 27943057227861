<template>
  <div class="footer">
    <div class="top">
      <div class="con">
        <div class="mod">
          <div class="title">关于商会</div>
          <div class="list">
            <div @click="$router.push('desc')">商会简介</div>
            <div @click="$router.push('build')">商会章程</div>
          </div>
        </div>
        <div class="mod">
          <div class="title">商会要闻</div>
          <div class="list">
            <div @click="$router.push('news')">商会要闻</div>
            <div @click="$router.push('member')">会员动态</div>
          </div>
        </div>
        <div class="mod">
          <div class="title">会员服务</div>
          <div class="list">
            <div @click="$router.push('service?active=0')">政策信息</div>
            <div @click="$router.push('service?active=1')">法律法规</div>
          </div>
        </div>
        <div class="mod">
          <div class="title">联系我们</div>
          <div class="list">
            <div>电话：4000-531-570</div>
            <div>地址：东岱生科产业园</div>
          </div>
        </div>

        <!-- <div class="qrcode">
                        <img src="@/static/images/demo.png" alt="" />
                        <div>公众号</div>
                    </div> -->
      </div>
    </div>
    <div class="bottom">
      <span>© 2022 东岱信息科技有限公司</span>
      <a href="https://beian.miit.gov.cn/" target="_blank"
        >鲁ICP备2023007015号</a
      >
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.footer {
  .top {
    background-image: url(@/static/images/nav-footer-bg.png);
    background-size: 100% 100%;
    width: 100%;
    height: 13.18vw;
    .con {
      width: 68.85vw;
      margin: 0 auto;
      display: flex;

      font-size: 1.04vw;
      line-height: 1.04vw;
      .mod {
        margin-right: 9.5vw;
        margin-top: 2.71vw;
        color: #151d46;
        .title {
          font-weight: bold;
          margin-bottom: 1.3vw;
        }
        .list {
          > div {
            cursor: pointer;
            margin-bottom: 1.46vw;
            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
      .qrcode {
        width: 7.6vw;
        text-align: center;
        margin-top: 2.71vw;
        img {
          width: 7.6vw;
          height: 7.6vw;
        }
        > div {
          margin-top: 0.47vw;
        }
      }
    }
  }
  .bottom {
    background-color: #002c6c;
    display: flex;
    justify-content: center;
    color: #fff;
    line-height: 2.27vw;
    height: 2.27vw;
    font-size: 0.9vw;
    span {
      margin-right: 1.46vw;
    }
    a {
      color: #fff;
      &:hover,
      &:active {
        color: #fff;
      }
    }
  }
}
</style>

<style lang="scss" scoped>
// 移动端响应式样式
@media screen and (max-width: 600px) {
  .footer {
    .top {
      height: auto !important;
      background-size: cover !important;

      .con {
        width: 90vw !important;
        margin-left: 5vw !important;
        font-size: 1.04vw * 2.5 !important;
        line-height: 1.04vw * 2.5 !important;
        flex-wrap: wrap;

        .mod {
          margin-right: auto;
          margin-top: 2.71vw * 2.5 !important;
          .title {
            margin-bottom: 1.3vw * 2.5 !important;
          }
          .list {
            > div {
              margin-bottom: 1.46vw * 2.5 !important;
              &:last-child {
                margin-bottom: 0;
              }
            }
          }
          &:last-child {
            margin-right: 0;
          }
        }
      }
    }

    .bottom {
      line-height: 4.27vw * 2.5 !important;
      height: 4.27vw * 2.5 !important;
      font-size: 1.04vw * 2.5 !important;
      span {
        margin-right: 1.46vw * 2.5 !important;
      }
    }
  }
}
</style>
